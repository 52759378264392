:root {
	/* colors */
	--main-green: #0d7037;
	--light-green: #34ff00b3;
	--grey: #333;
	--facebook-color: #1877f2;
	--primary-yellow: #d3d600;
	/* font's */
	--primary-font: "Oswald", sans-serif;
	--secondary-font: system-ui;
	--primary-color: #333;
	--hover-nav-color: #005521;
	--grey-color: #cccccc;
	--test-var: green;
	/* margins */
	--margin-sides: 50px;
}

html::-webkit-scrollbar {
	width: 10px;
}

html::-webkit-scrollbar-track {
	background-color: #f5fff0;
	border: 1px solid #cacaca;
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

html::-webkit-scrollbar-thumb {
	border-radius: 2px;
	background-color: #005521;
}

a {
	all: unset;
}

*,
html,
body {
	padding: 0;
	margin: 0;
	scroll-behavior: smooth;
	box-sizing: border-box;
	font-family: var(--primary-font, --secondary-font);
}

button {
	font-family: var(--primary-font);
}

#hide_navigation {
	z-index: 10;
	color: #000000;
	position: fixed;
	top: -80px;
	height: 80px;
	width: 100%;
	transition: top 0.2s linear;
}

.socialMedia-list {
	display: flex;
	justify-content: center;
	list-style: none;

	.socialMedia-item {
		margin-left: 15px;
	}

	.socialMedia-item:hover {
		cursor: pointer;
	}

	.socialMedia-item.facebook {
		fill: var(--facebook-color);
	}
}

#contactContainer {
	background-color: var(--main-green);
}

.contactHeader {
	padding: 100px 0;
	text-align: center;
}

.contactHeader svg {
	font-size: 40px;
	fill: #ffffff;
	position: relative;
}

.contactHeader h1 {
	padding: 50px 0;
	letter-spacing: 2px;
}

.contactContent {
	display: flex;
	padding: var(--margin-sides);

	& div {
		flex-basis: 50%;
	}
	& form {
		flex-basis: 50%;
		padding: 0 3%;
	}
}

label {
	display: block;
	margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
	width: 100%;
	padding: 8px;
	margin-bottom: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	background-color: #e6e6e6;
}

textarea {
	resize: vertical;
}

input[type="submit"] {
	background-color: #4caf50;
	color: white;
	padding: 10px 20px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

input[type="submit"]:hover {
	background-color: #45a049;
}
